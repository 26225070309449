import React, { useEffect, useState} from "react"
import { Helmet } from "react-helmet"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../styles/style.css"
import Ministerio from "../../static/images/kit-digital/ministerio.png"
import FondosEU from "../../static/images/kit-digital/nextgeneu_en.png"
import Red from "../../static/images/kit-digital/red.svg"
import R from "../../static/images/kit-digital/r.svg"
import Checklist from "../../static/images/icons/web/icon27.svg"
import Icon1 from "../../static/images/icons/web/icon4.svg"
import Icon2 from "../../static/images/icons/web/icon23.svg"
import Icon3 from "../../static/images/icons/web/icon19.svg"
import Icon4 from "../../static/images/icons/web/icon8.svg"
import Icon5 from "../../static/images/icons/web/icon25.svg"
import Icon6 from "../../static/images/icons/web/icon24.svg"

const KitDigital = () => {
  const data = useStaticQuery(graphql`
    query Images_kit {
      image: allFile(
        filter: { relativeDirectory: { eq: "kit-digital" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  function Mineco() {window.open ("https://portal.mineco.gob.es/es-es/Paginas/index.aspx");}
  function FondosNext() {window.open ("https://nexteugeneration.com/");}
  function red() {window.open ("https://red.es/es");}
  function Recuperación() {window.open ("https://planderecuperacion.gob.es/");}
  function Test() {window.open ("https://www.acelerapyme.es/quieres-conocer-el-grado-de-digitalizacion-de-tu-pyme");}

  const calculateTimeLeft = () => {
    let difference = +new Date(`Sep 2, 2023 11:00:00`) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        Months: Math.floor(difference / (1000 * 60 * 60 * 24 * 30)),
        Days: Math.floor(difference / (1000 * 60 * 60 * 24) % 30),
        Hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Minutes: Math.floor((difference / 1000 / 60) % 60),
        Seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setTimeout(() => {setTimeLeft(calculateTimeLeft());}, 1000);
    return () => clearTimeout(timer);
  });

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {return;}
  });

  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Programa Bono Kit Digital | Qualoom</title>
        <meta name="title" content="Programa Bono Kit Digital | Qualoom"/>
        <meta name="description" content="Iniciativa del Gobierno de España para ofrecer subvenciones a Pymes y Autónomos en implementación de soluciones digitales. ¡Solicita tu bono del Kit Digital!"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/kit-digital/"/>
        <link rel="canonical" href="https://www.qualoom.es/kit-digital/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/kit-digital/"/>
        <meta property="og:title" content="Programa Bono Kit Digital | Qualoom"/>
        <meta property="og:description" content="Iniciativa del Gobierno de España para ofrecer subvenciones a Pymes y Autónomos en implementación de soluciones digitales. ¡Solicita tu bono del Kit Digital!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/kit-digital/"/>
        <meta property="twitter:title" content="Programa Bono Kit Digital | Qualoom"/>
        <meta property="twitter:description" content="Iniciativa del Gobierno de España para ofrecer subvenciones a Pymes y Autónomos en implementación de soluciones digitales. ¡Solicita tu bono del Kit Digital!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/kit-digital/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Kit Digital", "item": {"@id": "https://www.qualoom.es/kit-digital/", "name": "Kit Digital"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-kit">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Kit Digital para PYMES y autónomos</h1>
              <p>Una iniciativa del Gobierno de España cuyo objetivo es ofrecer subvenciones para la implementación de soluciones digitales para conseguir un adecuado nivel de madurez digital.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué es el Kit Digital?</h2>
          <div className="section-main">
            <div className="kit-section-img"></div>
            <div className="kit-section-text">
              <p>Es un programa de ayudas financiado por la UE mediante los <b>fondos Next Generation EU</b>, con el objetivo de ayudar a Pymes y autónomos en su proceso de digitalización. Este programa está dotado de 3.000M € y está dentro del <b>Plan de Recuperación, Transformación y Resiliencia, y del Plan de Digitalización de Pymes 2021-2025.</b></p>
              <p>Todas las empresas y autónomos que cumplan los requisitos de solicitud del bono, podrán <b>mejorar y potenciar el ecosistema tecnológico de su organización a través de la implantación y uso de soluciones digitales</b>, como pueden ser: creación de páginas web, posicionamiento SEO, gestión de redes sociales, ciberseguridad, etc.</p>
              <div className="kit-buttons">
                <a href="/soluciones-kit-digital-qualoom/" className="servicios-button">Soluciones del Kit Digital de Qualoom</a>
                <a href="/solicitar-bono-kit-digital/" className="servicios-button">¿Cómo solicitar el Bono Digital?</a>
              </div>
            </div>
          </div><br/><br/>
          <div className="logos-kit">
            <img src={Ministerio} alt="Ministerio de asuntos tecnológicos y transformación digital" onClick={Mineco} onKeyDown aria-hidden></img>
            <img src={FondosEU} alt="Fondos Next Generation EU" onClick={FondosNext} onKeyDown aria-hidden></img>
            <img src={Red} alt="red.es" onClick={red} onKeyDown aria-hidden></img>
            <img src={R} alt="Plan de Recuperación, Transfromación y Resilencia" onClick={Recuperación} onKeyDown aria-hidden></img>
          </div>
        </div>
      </div>
      <div className="services-main count-down">
          <h2>Convocatoria de ayudas del Kit Digital para empresas del Segmento II</h2>
          <span>Empresas de entre 3 y menos de 10 empleados</span><br/>
          <span>Plazo de presentación de solicitudes hasta el <b>2 de septiembre de 2023 a las 11:00 AM</b></span>
          {(timeLeft.Days + timeLeft.Minutes + timeLeft.Hours) > 0 ? 
          <div className="time-left">
            <div className="clock-time"><span>{timeLeft.Months}</span><span>MESES</span> </div>
            <div className="clock-time"><span>{timeLeft.Days}</span><span>DÍAS</span> </div>
            <div className="clock-time"><span>{timeLeft.Hours}</span><span>HORAS</span> </div>
            <div className="clock-time hide">
              <span>{timeLeft.Minutes}</span>
              <span className="time-web">MINUTOS</span><span className="time-mobile">MIN</span>
            </div>
            <div className="clock-time hide">
              <span>{timeLeft.Seconds}</span>
              <span className="time-web">SEGUNDOS</span><span className="time-mobile">SEG</span>
            </div>
          </div>
          :
          <div className="time-left">
            <span>INICIO CONVOCATORIA PARA LA PRESENTACIÓN DE SOLICITUDES</span>
          </div>}
          <div className="kit-digital-video">
            <h3>Descubre más sobre los fondos NextGenEU</h3>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/VFYlYeFAEH4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>¿Quién puede beneficiarse del Kit Digital y sus soluciones?</h2>
          <p>Los beneficiarios podrán ser <b>pequeñas empresas, microempresas o autónomos</b> que necesiten y quieran digitalizar su empresa para ser más competitivas. Cualquier empresa que cumpla los requisitos mínimos sin importar el sector en el que se encuentre podrá solicitar este bono.</p><br/>
          <h3>Requisitos para solicitar el bono</h3>
          <div className="kit-list-items">
            <div className="kit-item"><img src={Checklist} alt="Requisito"></img><p>Ser pequeña empresa, microempresa o autónomo</p></div>
            <div className="kit-item"><img src={Checklist} alt="Requisito"></img><p>No incurrir en ninguna de las prohibiciones de la Ley de Subvenciones</p></div>
            <div className="kit-item"><img src={Checklist} alt="Requisito"></img><p>Estar inscrito en el Censo de empresarios, profesionales y retenedores</p></div>
            <div className="kit-item"><img src={Checklist} alt="Requisito"></img><p>No superar el límite de ayudas mínimas</p></div>
            <div className="kit-item"><img src={Checklist} alt="Requisito"></img><p>No tener consideración de empresa en crisis</p></div>
            <div className="kit-item"><img src={Checklist} alt="Requisito"></img><p>Realizar el <span className="kit-link" onClick={Test} onKeyDown aria-hidden>test de Madurez Digital</span></p></div>
            <div className="kit-item"><img src={Checklist} alt="Requisito"></img><p>Estar al corriente con SS y AEAT</p></div>
            <div className="kit-item"><img src={Checklist} alt="Requisito"></img><p>Realizar los trámites a través de la sede electrónica</p></div>
            <div className="kit-item"><img src={Checklist} alt="Requisito"></img><p>Mantener un sistema de contabilidad separada</p></div>
            <div className="kit-item"><img src={Checklist} alt="Requisito"></img><p>Cumplir con la ley y reportar otras ayudas para mismas actividades</p></div>
          </div><br/>
          <h3>¿Cómo podemos ayudarte en Qualoom?</h3>
          <div className="help-list-items">
            <div className="help-item"><img src={Icon1} alt="Asesoramiento"></img><p>Con nuestros servicios de asesoramiento y digitalización obtendrás los mejores resultados para tu empresa</p></div>
            <div className="help-item"><img src={Icon2} alt="Soluciones digitales"></img><p>Te ofrecemos los mejores servicios y soluciones tecnológicas para tus necesidades de digitalización</p></div>
            <div className="help-item"><img src={Icon3} alt="Funcionalidad"></img><p>Te garantizamos una correcta funcionalidad en todas las soluciones digitales que ofrecemos</p></div>
            <div className="help-item"><img src={Icon4} alt="Equipo cualificado"></img><p>Disponemos de un equipo altamente cualificado en los procesos de digitalización</p></div>
            <div className="help-item"><img src={Icon5} alt="Contenidos y recursos"></img><p>Tendrás a tu disposición varios contenidos y recursos para empresas y autónomos</p></div>
            <div className="help-item"><img src={Icon6} alt="Soporte"></img><p>Te sentirás acompañado durante todo el proceso de digitalización</p></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default KitDigital
